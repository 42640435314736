<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"
        ><span style="font-size: 12px">Aguarde</span></v-progress-circular
      >
    </v-overlay>

    <v-row dense>
      <v-badge
        tile
        :content="registros.length + ' Solicitações'"
        :value="registros.length"
      />
      <v-col>
        <DataTable
          style="
            background-color: #42a5f5;
            font-size: 12px;
            border-bottom: 1px solid #ccc;
            width: 100%;
          "
          :value="registros"
          class="p-datatable-gridlines"
          dataKey="sol_id"
          selectionMode="single"
          :selection.sync="itemSelecionado"
          :scrollable="true"
          scrollHeight="380px"
          :expandedRows.sync="expandedRows"
          @row-expand="onRowExpand"
        >
          <Column
            headerStyle="width: 5px; background-color: #eee; text-align: center"
            bodyStyle="width: 5px"
            footerStyle="width: 5px"
          >
            <template #body="item">
              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-show="!item.data.sol_status.includes('CAN')"
                    @click="itemSelecionado = item.data"
                    dark
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="black">mdi mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list dense>
                  <v-list-item
                    @click="cancelarsol(item.data)"
                    v-show="item.data.podecancelar === 'True'"
                  >
                    <v-list-item-icon>
                      <v-icon color="red">mdi mdi-minus-circle-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-subtitle
                        >Cancelar solicitação</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item @click="imprimir_sol(item.data.url)">
                    <v-list-item-icon>
                      <v-icon>mdi mdi-printer</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-subtitle
                        >Imprimir solicitação</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    @click="imprimir_sol(item.data.urlboleto)"
                    v-show="item.data.sol_status.includes('PAGA')"
                  >
                    <v-list-item-icon>
                      <v-icon>fas fa-barcode</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-subtitle
                        >Imprimir boleto</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    @click="parecer_sol"
                    v-show="item.data.sol_status.includes('PARECE')"
                  >
                    <v-list-item-icon>
                      <v-icon>fal fa-handshake-alt</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-subtitle
                        >Parecer conclusivo (Encerrar
                        solicitação)</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    @click="show_atend = true"
                    v-show="item.data.sol_status.includes('AGUAR')"
                  >
                    <v-list-item-icon>
                      <v-icon>mdi mdi-progress-wrench</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-subtitle
                        >Registrar atendimentos</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    @click="reprocessar(item.data)"
                    v-show="item.data.podebaixar === 'true'"
                  >
                    <v-list-item-icon>
                      <v-icon>mdi-wrap</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-subtitle
                        >Reprocessar baixa</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    @click="anexos"
                    v-show="item.data.sol_status.includes('AGUAR')"
                  >
                    <v-list-item-icon>
                      <v-icon>mdi-paperclip</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-subtitle>Anexos</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    @click="discussao = true"
                    v-show="item.data.sol_status.includes('AGUAR')"
                  >
                    <v-list-item-icon>
                      <v-icon>fal fa-comments</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-subtitle>Discussão</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    @click="encaminhar = true"
                    v-show="item.data.sol_status.includes('AGUAR')"
                  >
                    <v-list-item-icon>
                      <v-icon>fas fa-arrow-alt-circle-right</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-subtitle
                        >Encaminhar solicitação</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    @click="infmotrev = true"
                    v-show="item.data.serv_poderevogarparecer === 'True'"
                  >
                    <v-list-item-icon>
                      <v-icon>mdi mdi-backup-restore</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-subtitle
                        >Revogar parecer</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </Column>
          <Column
            :expander="true"
            headerStyle="width:5px; background-color: #eeeeee;"
            bodyStyle="width: 5px; font-size: 10px;"
          ></Column>
          <template #expansion="item">
            <div v-html="item.data.parecer"></div>
          </template>
          <Column
            headerStyle="width: 7px; background-color: #eee"
            fieldStyle="width: 7px; font-size: 50px; font-weight: bold;"
            header="Nro Sol"
            field="sol_id"
          >
            <template #body="item">
              <font style="font-size: 15px"
                ><b>{{ item.data.sol_id }}</b></font
              >
            </template>
          </Column>
          <Column
            headerStyle="width: 8px; background-color: #eee"
            fieldStyle="width: 8px"
            header="Data"
            field="sol_data"
          >
          </Column>
          <Column
            headerStyle="width: 50px; background-color: #eee"
            fieldStyle="width: 50px"
            header="Serviço"
            field="serv_descricao"
          >
            <template #body="item">
              <v-row dense>
                <v-col cols="1">
                  <v-icon
                    title="Abrir anexos"
                    @click="
                      itemSelecionado = item.data;
                      anexos(item.data);
                    "
                    v-show="item.data.anexo === 'true'"
                    >mdi mdi-paperclip</v-icon
                  >
                </v-col>
                <v-col cols="11">
                  <div
                    :style="{
                      'font-size': '13px',
                      'text-align': 'justify',
                      'text-decoration': item.data.sol_status.includes('CAN')
                        ? 'line-through'
                        : 'none',
                    }"
                    v-html="item.data.riservico"
                  ></div>
                  <div
                    style="
                      margin-left: 15px;
                      font-size: 11px;
                      text-align: justify;
                    "
                    v-html="item.data.sol_just"
                  ></div>
                </v-col>
              </v-row>
            </template>
          </Column>
          <Column
            headerStyle="width: 10px; background-color: #eee"
            fieldStyle="width: 120px"
            header="Usr destino"
            field="usr_nome"
          ></Column>
          <Column
            headerStyle="width: 10px; background-color: #eee"
            fieldStyle="width: 10px"
            header="Situação"
            field="sol_status"
          ></Column>
        </DataTable>
      </v-col>
    </v-row>

    <v-dialog v-model="show_atend" width="800" persistent>
      <v-card>
        <sysbar
          titulo="Registro de atendiemento"
          @fechar="show_atend = false"
        />
        <v-card-text>
          <atend :sol_id="itemSelecionado.sol_id" v-if="show_atend" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="CANCELARSOL" width="500">
      <v-card>
        <v-card-title
          >Cancelar solicitação {{ itemSelecionado.p_id }}</v-card-title
        >
        <v-card-text
          >Informe o motivo do cancelamento da solicitação
          <v-textarea v-model="motivo_cancelar_sol" outlined dense></v-textarea>
        </v-card-text>
        <v-card-actions style="margin-top: -40px">
          <Button
            style="margin-left: 20px"
            label="Confirmar"
            icon="mdi mdi-check"
            class="p-button-sm primary"
            @click="cancelarsol_confirmar"
          ></Button>
          <Button
            style="margin-left: 5px"
            label="Cancelar"
            icon="mdi mdi-close"
            class="p-button-sm"
            @click="CANCELARSOL = false"
          ></Button>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="CANCELARSOLxx" width="350">
      <confirme
        msg="Confirma o cancelamento da solicitação?"
        width="350"
        @senao="CANCELARSOL = false"
        @sesim="cancelarsol_confirmar"
      ></confirme>
    </v-dialog>

    <v-dialog v-model="consreport" fullscreen>
      <v-card>
        <v-card-text>
          <v-row>
            <v-col>
              <viewpdf
                v-if="consreport"
                :report="report"
                @overlay="evt_overlay"
                :fullscreen="true"
              ></viewpdf>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-footer absolute>
        <Button
          label="F e c h a r"
          iconPos="right"
          class="p-button-raissed"
          style="
            color: white;
            margin-left: 6px;
            width: 98.9%;
            font-weight: 500;
            font-family: 'Calibri';
          "
          @click="fecharrelatorio"
        />
      </v-footer>
    </v-dialog>

    <v-dialog v-model="infparecer" width="400">
      <v-card>
        <div style="padding: 10px; background-color: indigo; color: #ddd">
          Parecer conclusivo da solicitação.
          <br />
          Essa solicitação será encerrada após confimação.
        </div>
        <v-card-text>
          <v-row dense>
            <v-col>
              <label>Parecer</label>
              <v-select
                outlined
                dense
                :items="[
                  { label: 'DEFERIDO' },
                  { label: 'INDEFERIDO' },
                  { label: 'PROCESSANDO' },
                ]"
                item-value="label"
                item-text="label"
                v-model="parecer"
              ></v-select>
            </v-col>
          </v-row>

          <v-row
            v-show="acc.trim() === 'True'"
            dense
            style="margin-top: -20px; margin-bottom: 25px"
          >
            <v-col>
              <v-text-field
                label="Carga Horária aprovada"
                outlined
                dense
                hide-details
                v-model="acc_ch_aprovada"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row dense style="margin-top: -30px">
            <v-col>
              <label>Argumentação</label>
              <v-textarea
                outlined
                dense
                v-model="argumento_parecer"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row dense style="margin-top: -25px">
            <v-col>
              <div style="float: right">
                <Button label="Confirmar" @click="confirmar_parecer"></Button>
                <Button
                  label="Cancelar"
                  @click="infparecer = false"
                  style="margin-left: 5px"
                ></Button>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="discussao" width="600">
      <v-card>
        <sysbar titulo="Discussão" @fechar="discussao = false"></sysbar>
        <v-card-text>
          <solicitacoes-discussao
            v-if="discussao"
            :sol_id="itemSelecionado.sol_id"
          ></solicitacoes-discussao>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="novasol" width="800">
      <novasolicitacao
        v-if="novasol"
        @fecharnovasolicitacao="fecharnovasolicitacao"
        :aluno="aluno"
      ></novasolicitacao>
    </v-dialog>

    <v-dialog v-model="solanexos" width="500">
      <v-card>
        <sysbar
          titulo="Anexos da solicitação"
          @fechar="solanexos = false"
        ></sysbar>
        <v-card-text>
          <solicitacoes-anexos
            :aluno="itemSelecionado"
            v-if="solanexos"
            :solicitacao="itemSelecionado"
            :sol_id="itemSelecionado.sol_id"
          ></solicitacoes-anexos>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="encaminhar" width="600">
      <v-card>
        <sysbar titulo="Encaminhar solicitação" @fechar="encaminhar = false" />
        <v-card-text>
          <solicitacoes-encaminhar
            :sol_id="itemSelecionado.sol_id"
            v-if="encaminhar"
            @fechar_encaminhar="fechar_encaminhar"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="infmotrev" width="400">
      <v-card>
        <v-card-title
          ><small>Informe o motivo da revogação do parecer</small></v-card-title
        >
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col>
              <v-textarea
                outlined
                dense
                hide-details
                v-model="motivorevparecer"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <Button label="Confirmar" @click="revogarparecer"></Button>
              <Button
                label="Cancelar"
                style="margin-left: 10px"
                @click="infmotrev = false"
              ></Button>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import http from "@/servicos/http.ts";
import Config, {
  formatData,
  formatMoeda,
  hoje,
  enviarEmail,
} from "@/config.ts";
import confirme from "@/components/confirme";
import printer from "@/servicos/printer.ts";
import viewpdf from "@/components/viewpdf";
import novasolicitacao from "@/components/novasolicitacao";
import solicitacoesAnexos from "@/components/solicitacoes-anexos";
import sysbar from "@/components/sysbar";
import solicitacoesDiscussao from "@/components/solicitacoes-discussao";
import solicitacoesEncaminhar from "@/components/solicitacoes-encaminhar";
const atend = () => import("./solicitacoes-atendimentos");

export default {
  name: "respondersolicitacao-listasol",
  components: {
    atend,
    confirme,
    viewpdf,
    novasolicitacao,
    solicitacoesAnexos,
    sysbar,
    solicitacoesDiscussao,
    solicitacoesEncaminhar,
  },
  props: ["registros"],
  data() {
    return {
      acc: "False",
      acc_ch_aprovada: "0",
      show_atend: false,
      novasol: false,
      overlay: false,
      CANCELARSOL: false,
      statussol: [],
      status: "",
      consreport: false,
      infparecer: false,
      solanexos: false,
      discussao: false,
      infmotrev: false,
      encaminhar: false,
      motivorevparecer: "",
      parecer: "",
      argumento_parecer: "",
      report: "",
      formatData,
      expandedRows: [],
      motivo_cancelar_sol: "",
      itemSelecionado: "",
      enviarEmail,
      dataini: new Date().toLocaleDateString("pt-BR", {
        timeZone: "UTC",
      }),
      datafim: new Date().toLocaleDateString("pt-BR", {
        timeZone: "UTC",
      }),
    };
  },
  created() {
    //this.listastatus().then(() => {
    //  this.listasol();
    //});
  },
  methods: {
    fechar_encaminhar() {
      this.encaminhar = false;
      this.listasol();
    },
    revogarparecer() {
      http
        .send("", {
          proc: "[fx jaguar alunos]",
          1: "revogarparecer",
          2: this.itemSelecionado.sol_id,
          3: this.$store.state.contexto.BD[0].usr_id,
          4: this.motivorevparecer,
        })
        .then((res) => {
          this.$toast.success("Parecer revogado com sucesso");
          this.listastatus().then(() => {
            this.listasol();
          });
          this.infmotrev = false;
        });
    },
    anexos(item) {
      //this.itemSelecionado = item;
      this.solanexos = true;
    },
    novaparcela() {
      this.novasol = true;
    },
    fecharnovasolicitacao(item) {
      this.novasol = false;
      if (item === "reload") {
        this.listastatus().then(() => {
          this.listasol();
        });
      }
    },
    confirmar_parecer() {
      http
        .send("", {
          proc: "[FX jaguar atendAluno parecerSol]",
          1: this.itemSelecionado.ra_id,
          2: this.parecer,
          3: this.argumento_parecer,
          4: this.$store.state.contexto.BD[0].usr_id,
          5: this.acc_ch_aprovada,
        })
        .then((res) => {
          this.infparecer = false;
          this.$toast.success("Parecer registrado com sucesso!");
          var resultado_parecer = res.dados.data[0];
          this.listastatus().then(() => {
            this.listasol().then((res) => {
              this.itemSelecionado = this.registros[0];

              if (
                this.parecer === "DEFERIDO" &&
                resultado_parecer.serv_msg_email_deferido !== ""
              )
                enviarEmail(
                  resultado_parecer.destino,
                  `Resposta de solicitação - ${resultado_parecer.sol_status}`,
                  resultado_parecer.msg_email
                )
                  .then(() => {
                    this.$toast.success("Mensagem enviada para aluno");
                  })
                  .then(() => {
                    this.overlay = false;
                  });

              if (
                this.parecer === "INDEFERIDO" &&
                resultado_parecer.serv_msg_email_deferido !== ""
              )
                enviarEmail(
                  resultado_parecer.destino,
                  `Resposta de solicitação - ${resultado_parecer.sol_status}`,
                  resultado_parecer.msg_email
                ).then(() => {
                  this.overlay = false;
                  this.$toast.success(
                    "Email para endereço " +
                      resultado_parecer.email +
                      " enviado com sucesso "
                  );
                });
            });
          });
        });
    },
    parecer_sol() {
      console.log("parecer", this.itemSelecionado);
      this.acc = this.itemSelecionado.acc;

      if (this.itemSelecionado.tarefas_liberadas === "não") {
        this.$toast.info(
          "Parecer está condicionado a connclusão das tarefas. Existe(m) tarefa(s) não concluída(s)",
          "Informação"
        );
      } else {
        this.parecer = "DEFERIDO";
        this.infparecer = true;
      }
    },
    imprimir_sol(url) {
      printer.imprimir(url.replace("w100", "TURMAS")).then((res) => {
        this.report = res;
        this.consreport = true;
      });
    },
    fecharrelatorio() {
      this.consreport = false;
    },

    onRowExpand(item) {
      this.expandedRows = [];
      this.expandedRows.push(item.data);
      this.itemSelecionado = item.data;
    },

    atualizaregistros(item) {
      this.status = item;
      this.listasol();
    },
    reprocessar(item) {
      this.overlay = true;
      http
        .send("", {
          proc: "[fx jaguar gera_req]",
          0: item.sol_id,
          1: "",
          2: this.$store.state.contexto.BD[0].usr_id,
        })
        .then((res) => {
          this.overlay = false;
          this.$toast.success(
            `Solicitação <b>${item.sol_id} reprocessada</b> com sucesso!`
          );
          this.listasol();
        });
    },
    cancelarsol(item) {
      this.CANCELARSOL = true;
    },
    cancelarsol_confirmar() {
      this.CANCELARSOL = false;
      this.overlay = true;
      http
        .send("", {
          proc: "[FX jaguar alunos]",
          1: "cancelasol",
          2: this.itemSelecionado.sol_id,
          3: this.$store.state.contexto.BD[0].usr_id,
          4: this.motivo_cancelar_sol,
        })
        .then((res) => {
          this.overlay = false;
          this.$toast.success(
            `Solicitação <b>${this.itemSelecionado.sol_id} cancelada</b> com sucesso!`
          );
          this.listastatus(() => {
            this.listasol();
          });
        });
    },
    async listastatus() {
      this.overlay = true;
      await http
        .send("", {
          proc: "[fx jaguar listas]",
          0: "statussol",
          1: this.itemSelecionado.a_id,
        })
        .then((res) => {
          this.overlay = false;
          this.statussol = res.dados.data;
          try {
            this.status = this.statussol.filter(
              (s) => s.ss_descricao === "AGUARDANDO PARECER"
            )[0].ss_filtro;
          } catch (e) {
            this.status = "%";
          }
        });
    },
    async listasol() {
      this.overlay = true;
      await http
        .send("", {
          proc: "[FX jaguar alunos]",
          1: "listasol",
          2: this.itemSelecionado.a_id,
          3: "",
          4: this.$store.state.contexto.BD[0].usr_id,
          5: "", // Tipo de busca
          6: "", // Curso
          7: "", // Escola
          8: "TODOS", // Serviços
          9: "0", // Nro SOlicitação
          10: this.status, // Situação da solicitação
          11: "", // Data Inicial
          12: "",
        })
        .then((res) => {
          this.overlay = false;
          if (res.dados.data[0].sol_id) {
            this.registros = res.dados.data;
            this.itemSelecionado = this.registros[0];
          } else {
            this.registros = "";
            this.itemSelecionado = "";
          }
        });
    },
    rowClass(item) {
      if (item.sol_status) {
        if (item.sol_status.includes("CAN")) {
          return "riscado";
        }
      }
    },
  },
};
</script>

<style>
.riscado {
  text-decoration: line-through;
}
.normal {
  text-decoration: none !important;
}
</style>
