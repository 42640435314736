<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"
        ><span style="font-size: 12px">Aguarde</span></v-progress-circular
      >
    </v-overlay>
    <v-row>
      <v-col>
        <div v-show="inc">
          <v-file-input
            v-model="arquivos"
            outlined
            dense
            label="Arquivos"
            multiple
            prepend-icon="mdi-paperclip"
          >
            <template v-slot:selection="{ text }">
              <v-chip small label color="primary">
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>

          <Button label="Confirmar" @click="confirmar"></Button>
          <Button
            label="Cancelar"
            @click="inc = false"
            style="margin-left: 10px"
          ></Button>
        </div>

        <DataTable
          v-show="!inc"
          style="background-color: #42a5f5; font-size: 12px"
          :value="registros"
          class="p-datatable-gridlines"
          selectionMode="single"
          :selection.sync="itemSelecionado"
          :scrollable="true"
          scrollHeight="200px"
        >
          <Column headerStyle="width: 4em; background-color: #eee">
            <template #header="item">
              <v-icon @click="inc = true" title="Incluir novo anexo"
                >mdi mdi-plus-circle</v-icon
              >
            </template>
            <template #body="item">
              <v-icon
                v-show="source == !'portalaluno' || !source"
                color="red"
                @click="
                  excl = true;
                  itemSelecionado = item.data;
                "
                title="Excluir anexo"
                >mdi mdi-minus-circle</v-icon
              >
            </template>
          </Column>
          <Column headerStyle="width: 4em; background-color: #eee">
            <template #body="item">
              <v-icon
                title="Visualizar doc"
                @click="
                  itemSelecionado = item.data;
                  viewdoc(item.data);
                "
                >mdi mdi-file-find</v-icon
              >
            </template>
          </Column>
          <Column
            headerStyle="background-color: #eee"
            header="Data"
            field="sa_nomearquivo"
          >
            <template #body="item">
              {{ item.data.sa_nomearquivo }}
            </template>
          </Column>
        </DataTable>
      </v-col>
    </v-row>

    <v-dialog v-model="consreport" fullscreen>
      <v-card>
        <v-card-text>
          <v-row>
            <v-col>
              <viewpdf
                v-if="consreport"
                :report="report"
                @evt_overlay="overlay = false"
                :fullscreen="true"
              ></viewpdf>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-footer absolute>
        <Button
          label="F e c h a r"
          iconPos="right"
          class="p-button-raissed"
          style="
            color: white;
            margin-left: 6px;
            width: 98.9%;
            font-weight: 500;
            font-family: 'Calibri';
          "
          @click="consreport = false"
        />
      </v-footer>
    </v-dialog>

    <v-dialog v-model="excl" width="300">
      <confirme @senao="excl = false" @sesim="excluir"></confirme>
    </v-dialog>
  </div>
</template>

<script>
import http from "@/servicos/http.ts";
import Config, { formatData, formatMoeda, hoje } from "@/config.ts";
import axios from "axios";
import confirme from "@/components/confirme";
import viewpdf from "@/components/viewpdf";
import printer from "@/servicos/printer.ts";

export default {
  name: "solicitacoes-anexos",
  props: ["sol_id", "aluno", "solicitacao", "source"],
  components: { confirme, viewpdf },
  data() {
    return {
      overlay: false,
      inc: false,
      excl: false,
      consreport: false,
      report: "",
      registros: "",
      itemSelecionado: "",
      arquivos: [],
    };
  },
  created() {
    this.aluno.cga = this.aluno.a_id;
    console.log(this.aluno);
    this.listaanexos();
  },
  methods: {
    viewdoc_antigo(item) {
      printer.imprimirPDF("", { sql: item.sa_id }).then((res) => {
        this.report = res;
        this.consreport = true;
      });
    },
    viewdoc(item) {
      if (item.sa_nomearquivo.includes(".jpg")) {
        this.report =
          Config.baseApiUrl +
          `/${this.$store.state.contexto.BD[0].bco_pasta.trim()}/alunos/${
            this.aluno.cga
              .replace(".", "")
              .replace(".", "")
              .replace("-", "")
              .trim() +
            "/solicitacoes/" +
            this.sol_id
          }/${this.itemSelecionado.sa_nomearquivo}`;
        this.consreport = true;
      } else {
        if (this.solicitacao.anexoantigo === "True") {
          this.viewdoc_antigo(item);
        } else {
          this.abrepdf(item);
          /*
          this.itemSelecionado = item;
          this.report =
            Config.baseApiUrl +
            `/sendMePDF?destino=/jaguar/${this.$store.state.contexto.BD[0].bco_pasta.trim()}/alunos/${
              this.aluno.cga
                .replace(".", "")
                .replace(".", "")
                .replace("-", "")
                .trim() +
              "/solicitacoes/" +
              this.sol_id
            }/${this.itemSelecionado.sa_nomearquivo}&filename=${
              this.itemSelecionado.sa_nomearquivo
            }`;
          this.consreport = true;
          console.log(this.report);
*/
        }
      }
    },

    abrepdf(item) {
      const action = Config.baseApiUrlUpload; //Config.baseApiUrl + "/sendMePDF";

      const destino = `/${this.$store.state.contexto.BD[0].bco_pasta.trim()}/alunos/${
        this.aluno.cga
          .replace(".", "")
          .replace(".", "")
          .replace("-", "")
          .trim() +
        "/solicitacoes/" +
        this.sol_id +
        "/" +
        this.itemSelecionado.sa_nomearquivo
      }`;

      const anexo = action + destino;

      const anchor = window.document.createElement("a");
      anchor.href = anexo; //window.URL.createObjectURL(blob);
      anchor.target = "blank";
      anchor.download = this.itemSelecionado.sa_nomearquivo;
      anchor.click();
      window.URL.revokeObjectURL(anchor.href);
      /*
      const form = document.createElement("form");
      form.setAttribute("method", "get");
      form.setAttribute("action", action);
      form.setAttribute("target", "_blank");

      const FN = document.createElement("input");
      FN.setAttribute("type", "hidden");
      FN.setAttribute("name", "destino");
      FN.setAttribute("id", "destino");
      FN.value = destino; //this.itemSelecionado.sa_nomearquivo;
      form.appendChild(FN);

      const s = document.createElement("input");
      s.setAttribute("type", "submit");
      s.setAttribute("value", "Submit");
      form.appendChild(s);

      //      const destino = this.itemSelecionado.sa_nomearquivo;

      const anchor = form; //window.document.createElement("form");

      //anchor.action = action; //window.URL.createObjectURL(blob);
      //      anchor.target = "blank";
      //anchor.action = anexo;
      //      anchor.method = "post";
      //anchor.download = "hashmanchete";
      //anchor.submit(); //click();
      //      window.URL.revokeObjectURL(anchor.href);
      document.body.appendChild(form);
      s.click();
      document.body.removeChild(form);
      */
    },
    abrepdfx(item) {
      var av_id = item.av_id;
      var anexo = item.anexo;
      var manchete = item.manchete;
      console.log("abrepdf");
      anexo =
        Config.baseApiUrl +
        `/${this.$store.state.contexto.BD[0].bco_pasta.trim()}/alunos/${
          this.aluno.cga
            .replace(".", "")
            .replace(".", "")
            .replace("-", "")
            .trim() +
          "/solicitacoes/" +
          this.sol_id
        }/${this.itemSelecionado.sa_nomearquivo}`;

      console.log("anaexo", anexo);
      const anchor = window.document.createElement("a");
      anchor.href = anexo; //window.URL.createObjectURL(blob);
      anchor.target = "blank";
      //anchor.action = anexo;
      anchor.method = "post";
      //anchor.download = "hashmanchete";
      anchor.click();
      window.URL.revokeObjectURL(anchor.href);
      //      });
    },

    listaanexos() {
      this.overlay = true;
      http
        .send("", {
          proc: "[fx jaguar listas]",
          1: "listaanexossol",
          2: this.sol_id,
        })
        .then((res) => {
          this.registros = res.dados.data;
          this.overlay = false;
        });
    },
    excluir() {
      http
        .send("", {
          proc: "[fx jaguar listas]",
          1: "delanexossol",
          2: this.itemSelecionado.sa_id,
        })
        .then((res) => {
          this.overlay = false;
          this.registros = this.registros.filter(
            (r) => r.sa_id !== this.itemSelecionado.sa_id
          );
          this.excl = false;
        });
    },
    confirmar() {
      this.overlay = true;
      for (let index = 0; index < this.arquivos.length; index++) {
        this.upload(this.arquivos[index]);
      }
      this.overlay = false;
      this.inc = false;
      this.listaanexos();
    },
    async upload(file) {
      await http
        .send("", {
          proc: "[fx jaguar listas]",
          1: "incanexosol",
          2: this.sol_id,
          3: file.name,
        })
        .then((res) => {
          const formData = new FormData();
          const url =
            Config.baseApiUrlUpload +
            `/upload?destino=${this.$store.state.contexto.BD[0].bco_pasta.trim()}/alunos/${
              this.aluno.cga
                .replace(".", "")
                .replace(".", "")
                .replace("-", "")
                .trim() + "/solicitacoes/"
            }&codigo=${this.sol_id}`;

          formData.append("file", file);

          axios.post(url, formData).then((res) => {
            this.$toast.success("uploaded " + file.name);
            this.listaanexos();
          });
        });
    },
  },
};
</script>

<style></style>
